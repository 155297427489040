@use "src/styles/0-base/_responsive.scss" as *;

.borrower_container-big {
  padding: 40px;
  display: flex;
  gap: 20px;
  flex-direction: column;
  align-items: center;
  .header {
    width: 100%;
  }
  @media (max-width: $tablet) {
    padding: 20px;
  }
  @media (max-width: $mobile) {
    padding: 10px;
  }
}

.title_financing-code {
  width: 100%;
  margin: 30px 0;
  h1 {
    color: var(--light-mode-grey13);
    font-weight: bolder;
  }
}

@media (min-width: $desktop) {
  .title_financing-code {
    h1 {
      font-size: 32px;
      font-weight: bold;
    }
  }
}

@media (max-width: $mobile) {
  .title_financing-code {
    margin: 30px 0 10px 0;
    h1 {
      margin: 0;
    }
  }
}
