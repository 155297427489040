@use "src/styles/0-base/_responsive.scss" as *;
.reg_container{
    background-color:transparent;
    display: flex;
    justify-content: center;
    align-items: center;

    .Page_container{
        margin: 30px auto;
        min-height: 600px;
        background-color:var(--white);
        width: 1366px;
        max-width: 90%;
        img{
            width: 120px;
            margin: 20px 20px 5px 20px;
        }
        .reg_content{
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
        .title_content{
            color:var(--white);
            background-image:linear-gradient(to right, var(--secondary) 50%, var(--primary) 50%) ;
            background-position: center;
            background-size:cover;
            background-repeat: no-repeat;
            background-position-y:-30px;
            height: 340px;
            width: 95%;
            display: flex;
            align-items: center;
            justify-content: center;
            h2{
                font-size: 30px;
                font-weight: 400px;
                text-align: center;
            }
            p{
                width: 100%;
            }          
        }
    }
}
}
@media (max-width: $tablet){
    .reg_container{
        width: 100%;
        .Page_container{
            background-color:var(--white);
            max-width: 95%;
        }}
}