@use "src/styles/0-base/_responsive.scss" as *;

.main-color-button {
  background-color: var(--light-mode-grey12);
  color: white;
  border: none;
  width: 614px;
  border-radius: 8px;
  height: 46px;
  font-size: 14px;
  cursor: pointer;
  &:hover {
    background-color: var(--light-mode-grey11);
  }
  &:disabled {
    background-color: var(--light-mode-grey4);
    color: var(--light-mode-grey9);
    cursor: auto;
  }
}

.stroked-button {
  width: 148px;
  height: 46px;
  border-radius: 8px;
  border: 1px solid var(--light-mode-grey8);
  background-color: var(--light-mode-grey1);
  font-size: 14px;
  cursor: pointer;
  &:hover {
    background-color: var(--light-mode-grey4);
  }
  &:disabled {
    color: var(--light-mode-grey9);
    border: 1px solid var(--light-mode-grey6);
    background-color: var(--light-mode-grey2);
  }
}
.stroked-button-small {
  width: fit-content;
  display: flex;
  gap: 8px;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid var(--light-mode-grey8);
  background-color: var(--light-mode-grey1);
  font-size: 14px;
  cursor: pointer;
  &:hover {
    background-color: var(--light-mode-grey4);
  }
  &:disabled {
    color: var(--light-mode-grey9);
    border: 1px solid var(--light-mode-grey6);
    background-color: var(--light-mode-grey2);
  }
  p{
    margin: 0;
  }
}

.main-light-button{
  background-color: var(--brand-blue);
  border: 1px solid var(--brand-blue);
  border-radius: 50px;
  color: var(--brand-dark-blue);
  padding: 12px 32px;
  cursor: pointer;
  transition: 0.3s;
  p{
    margin: 0;
    font-size: 16px;
  }
  &:hover {
    background-color: #62E3FF;
  }
}

.main-stroked-button {
  border-radius: 50px;
  border: 1px solid var(--brand-dark-blue);
  color: var(--brand-dark-blue);
  padding: 12px 32px;
  background-color: transparent;
  transition: 0.3s;
  cursor: pointer;
  p {
    margin: 0;
    font-size: 16px;
  }
  &:hover {
    background-color: var(--brand-blue);
    border: 1px solid var(--brand-blue);
  }
}

.main-dark-button {
  background-color: var(--brand-dark-blue);
  border-radius: 50px;
  border: none;
  color: var(--brand-dark-blue);
  padding: 12px 32px;
  cursor: pointer;
  p {
    margin: 0;
    font-size: 16px;
    color: white;
  }
  &:disabled {
    background-color: var(--light-mode-grey4);
    color: var(--light-mode-grey9);
    cursor: auto;
  }
}

.transparent-button {
  border: none;
  background-color: transparent;
  padding: 12px 16px;
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  border-radius: 30px;
  color: var(--brand-dark-blue);
  transition: 0.3s;
  p {
    margin: 0;
    font-size: 16px;
    display: flex;
    align-items: center;
    gap: 10px;
  }
  &:hover {
    background-color: #EAE9DF;
  }
  &:focus {
    background-color: #EAE9DF;
  }
}

.select {
  select {
    border: 1px solid var(--light-mode-grey8);
    background-color: white;
    height: 64px;
    border-radius: 8px;
    background: url("../../assets/svg/dropdown.svg") no-repeat;
    background-position: calc(100% - 1rem) center !important;
    -moz-appearance: none !important;
    -webkit-appearance: none !important;
    appearance: none !important;
    padding-right: 2rem !important;
    padding: 8px 0px 8px 12px;
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
    cursor: pointer;
    color: var(--slate-palette-500);
    width: 414px;
    &:focus {
      outline: none;
    }
  }
}

//BOTONES VIEJOS

.button {
  background: none;
  border: solid 1px #009bae;
  color: #009bae;
  /* padding: 6px 12px; */
  cursor: pointer;
  &:hover {
    background-color: #009bae;
    color: white;
  }
  .mat-icon {
    vertical-align: middle;
  }
  p {
    margin: 0;
    margin-inline: 5px;
  }
}

.button-primary {
  background: none;
  border: solid 1px var(--light-mode-grey13);
  color: var(--light-mode-grey13);
  /* padding: 6px 12px; */
  cursor: pointer;
  &:hover {
    background-color: var(--light-mode-grey13);
    color: white;
  }
  .mat-icon {
    vertical-align: middle;
  }
  p {
    margin: 0;
    margin-inline: 5px;
  }
}

.cancel-button {
  background: none;
  border: solid 1px #999;
  color: #009bae;
  padding: 6px 12px;
  cursor: pointer;
  &:hover {
    background-color: #999;
    color: white;
  }
  .mat-icon {
    vertical-align: middle;
  }
  p {
    margin: 0;
    margin-inline: 5px;
  }
}

@media (max-width: $mobile) {
  .main-color-button {
    font-size: 16px;
  }
  .stroked-button {
    font-size: 16px;
  }
}
