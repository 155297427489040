
@mixin box {
    color: white;
    text-align: center;
    align-items: center;
    border-radius: 3px;
    padding-inline: 5px;
    margin-right: 8px;
    min-width: 80px;
    font-size: 11px;
    
}

.Cancelada {
    @include box;
    background-color: #a8a8a8;
}

.Azules {
    @include box;
    background-color:#74bad6;
}

.En-Estudio{
    @include box;
    background-color:#ec9652;
  }

.Verdes{
    @include box;
    background-color: #b4d48a;
}

.VerdeOscuro{
    @include box;
    background-color: #5cb85c;
}

.Analisis{
    @include box;
    /* background: linear-gradient(90deg, #74bad6 50%, #b4d48a 50%); */
    background:rgb(213, 213, 8)
}

.Denegada {
    @include box;
    background-color: #eb6060;
}

