/* You can add global styles to this file, and also import other style files */
/* @import url("https://fonts.googleapis.com/icon?family=Material+Icons"); */

@use "./0-base/base_dir" as *;
@use "./1-components/base_dir" as *;
@use 'ngx-owl-carousel-o/lib/styles/scss/owl.carousel' as *;
@use 'ngx-owl-carousel-o/lib/styles/scss/owl.theme.default' as *;
html,
body {
  height: 100%;
  /* scroll-behavior: smooth; */
}

html {
  overflow-y: auto;
  scrollbar-color: whitesmoke var(--brand-dark-blue);
  scrollbar-width: none;
}
body {
  margin: 0;
  width: 100%;
  /*     min-height: calc(100vh - 200.56px); */
  /* overflow-y: auto; */
  scrollbar-color: whitesmoke var(--brand-dark-blue);
  scrollbar-width: none;
}
* {
  font-family: var(--font) !important;
}
h3{
  margin: 0px;
}


mat-icon {
  font-family: "Material Icons" !important;
}
.mat-tab-labels {
  justify-content: center !important;
}

div.mat-expansion-panel-body {
  padding: 0 !important;
}

html.cdk-global-scrollblock {
  position: initial !important;
}

html.cdk-global-scrollblock body {
  position: fixed;
}

//inputs standard de material 15
.mdc-text-field--filled,
.mat-mdc-text-field-wrapper:hover,
.mat-mdc-form-field-focus-overlay {
  background-color: transparent !important;
}

.mat-mdc-paginator-container {
  background-color: var(--light-mode-grey1);
}

.mat-mdc-slider {
  --mdc-slider-handle-color: var(--brand-blue);
  --mdc-slider-focus-handle-color: var(--brand-blue);
  --mdc-slider-active-track-color: var(--brand-blue);
  --mdc-slider-inactive-track-color: white;
  --mat-mdc-slider-ripple-color: var(--brand-blue);
  --mat-mdc-slider-hover-ripple-color: var(--brand-blue);
  --mdc-slider-disabled-handle-color: var(--brand-blue);
  --mdc-slider-disabled-active-track-color: var(--brand-blue);
}

.mat-mdc-dialog-container {
  --mdc-dialog-container-shape: 16px;
}

.mat-form-field-appearance-outline .mat-form-field-outline {
  border-radius: 30px !important;
}

.mdc-text-field--outlined {
  --mdc-outlined-text-field-container-shape: 10px !important;
}

.selector-product-simulator{
  .mdc-text-field--outlined {
    --mdc-outlined-text-field-container-shape: 3px !important;
  }
}

/* .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: var(--brand-dark-blue) !important;
} */

.mat-sm > .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex {
  height: 135px !important;
}

.mat-mdc-progress-spinner .mdc-circular-progress__indeterminate-circle-graphic {
  stroke: var(--brand-blue) !important;
}

.span-selector{
  .mat-mdc-select-value-text{
    font-weight: 700;
  }
}

.header-fixed {
  position: fixed;
  z-index: 10;
  top:0;
  width: 100%
}
