/* You can add global styles to this file, and also import other style files */
/* @import url("https://fonts.googleapis.com/icon?family=Material+Icons"); */

@use './0-base/base_dir' as *;
@use './1-components/base_dir' as *;

html, body { height: 100%; }
body {
    margin: 0;
    width: 100%;
/*     min-height: calc(100vh - 200.56px); */
}
*{
    font-family: var(--font);
}

mat-icon{
    font-family: 'Material Icons' !important;
}
.mat-tab-labels{
    justify-content: center !important;
}



html.cdk-global-scrollblock {
    position: initial !important;
}
  
html.cdk-global-scrollblock body {
    position: fixed;
}

//inputs standard de material 15
.mdc-text-field--filled, .mat-mdc-text-field-wrapper:hover, .mat-mdc-form-field-focus-overlay {
    background-color: transparent !important
  }

