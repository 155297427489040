@use "src/styles/0-base/_responsive.scss" as *;
.container {
  margin: 0px 42.5px;
  padding: 0px 15px;
  background-color: white;
}
.page-container {
  background-color: white;
  max-width: 1366px;
  margin: 0 auto;
  .img-container {
    height: 350px;
    background-repeat: no-repeat;
    background-size: cover;
    max-width: 95%;
    margin: 0 auto;
    display: flex;
  }
}
@media (max-width:$mobile) {
  .page-container{
    max-width: 90%;
    margin-inline:auto;
  }
}




