@use "src/styles/0-base/_responsive.scss" as *;
.finance_card{
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--primary);

    img{
        width: 80px ;
    }
    .title_card{
        font-size: 20px;
        width: 100px;
    }
    .text_card{
        font-size: 45px;
        font-weight: bold;
        width: 100px;
        span{
            font-size: 20px;
        }
    }
}
@media (max-width:$mobile){
    .finance_card{
        /* flex-direction: column; */
        img{
            width: 50px !important;
        }
        .title_card{
            font-size: 15px;
        }
        .text_card{
            font-size: 30px;
            span{
                font-size: 14px;
            }
        }
    }
}