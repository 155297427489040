.button{
    background: none;
    border: solid 1px #009bae;
    color: #009bae;
    /* padding: 6px 12px; */
    cursor: pointer;
    &:hover{
        background-color: #009bae;
        color: white;
    }
    .mat-icon {
        vertical-align: middle;
    }
    p{
        margin: 0;
        margin-inline: 5px;
    }
}

.button-primary{
    background: none;
    border: solid 1px var(--primary);
    color: var(--primary);
    /* padding: 6px 12px; */
    cursor: pointer;
    &:hover{
        background-color: var(--primary);
        color: white;
    }
    .mat-icon {
        vertical-align: middle;
    }
    p{
        margin: 0;
        margin-inline: 5px;
    }
}

.cancel-button{
    background: none;
    border: solid 1px #999;
    color: #009bae;
    padding: 6px 12px;
    cursor: pointer;
    &:hover{
        background-color: #999;
        color: white;
    }
    .mat-icon {
        vertical-align: middle;
    }
    p{
        margin: 0;
        margin-inline: 5px;
    }
}