@use "src/styles/0-base/_responsive.scss" as *;
.reg_container{
    /* background-image: linear-gradient(to right, var(--primary) 0%, var(--primary) 100%); */
    padding: 0px 15px 15px 15px;
    .Page_container{
        padding: 0px 15px 15px 15px;
    }
    .items-affiliate-contain{
        display: flex;
        flex-direction: column;
        padding: 20px 100px 20px;
        .affiliate-title{
            font-size: 35px;
            font-weight: bolder;
            color: var(--primary);
            margin: 20px 0 20px 0;
            line-height: normal;
        }
    }
}

.graphic{
    width: 400px;
    border: solid 1px black;
    margin-bottom: 20px;
    .title-graphic{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 10px 15px 10px 15px;
        align-items: center;
        border-bottom: solid 1px black;
        p{
            text-transform: uppercase;
            margin: 0;
            
        }
    }
}

@media (max-width: $large){
    .reg_container{
        .items-affiliate-contain{
            padding: 20px 10px 20px;
        }
    }
}

@media (max-width: $tablet){
    .reg_container{
        width: 100%;
        padding: 0;
        .items-affiliate-contain{
            padding: 20px 0px 20px;
            margin: 0 20px;
            .affiliate-title{
                font-size: 25px;
            }
        }
        .Page_container{
            margin: 0;
            padding: 0;
         }
    }
    .graphic{
        width: 300px;
    }
}