@use "src/styles/0-base/_responsive.scss" as *;

.basic_form{
    width: 350px;
    display:flex;
    flex-direction: column;
    justify-content: center;
}

  .borrower_form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    h3{
        margin-top: 30px;
    }
    .cotitular_container,
    .jobData_container,
    .residenceData_container,
    .balance_container,
    .personal_container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
      .mat-form-field{
        ::ng-deep.mat-form-field-underline {
            /*change color of underline*/
            background-color: var(--light-mode-grey9);
            font-size: 10px;
          } 
      }
      .birth_date{
        display: flex;
        flex-direction: column;
        justify-content: center;
        /* margin-top: -7px; */
      }
    }
    .button_container{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
  

    mat-form-field {
      margin: 15px;
    }
    
    button {

      margin: 15px auto;
      width: 80%;
    }
    h3 {
      font-size: 24px;
      color: var(--light-mode-grey13);
      font-weight: 400;
      display: flex;
      gap: 5px;
      align-items: baseline;
      margin-bottom: 40px ;
      padding-left: 20px;
      span {
        font-size: 16px;
        color: rgb(135, 133, 133);
        margin-left: 5px;
      }
    }
    h4 {
        margin-left: 15px;
      color: rgb(62, 62, 62);
    }
  }


@media (max-width: $tablet) {
    .borrower_form{
        margin: 15px 5px;
        h3{
            margin-left: 15px;
        }
    .cotitular_container,
    .jobData_container,
    .residenceData_container,
    .balance_container,
    .personal_container{
        text-align: left;
        width: 100%;
      .date-content{
          .mat-form-field-suffix{
            flex:auto;
          }
        }
        input,
        mat-form-field {
           width: 100%;
          }
    }
    }
}

@media (max-width: $mobile){
  .borrower_form{
    margin: 0;
    width: 80%;
    mat-form-field{
      margin: 0;
    }
    mat-label{
      font-size: 12px;
    }
    mat-error{
      font-size: 10px;
    }
    h3{
      margin: 20px 0;
      padding: 0;
      font-size: 16px;
      span{
        font-size: 12px;
      }
    }
    .cotitular_container{
      width: auto;
    }
  }
}