
// Import material theming functions
@use 'SASS:map';
@use '@angular/material' as mat;

//theme 
$md-primary: (
    50 : #e0e6e8,
    100 : #b3c0c5,
    200 : #80969f,
    300 : #4d6b78,
    400 : #264c5b,
    500 : #002c3e,
    600 : #002738,
    700 : #002130,
    800 : #001b28,
    900 : #00101b,
    A100 : #59a7ff,
    A200 : #268bff,
    A400 : #0071f2,
    A700 : #0065d9,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #ffffff,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);

/* For use in src/lib/core/theming/_palette.scss */
$md-secondary: (
    50 : #e0f3f5,
    100 : #b3e1e7,
    200 : #80cdd7,
    300 : #4db9c6,
    400 : #26aaba,
    500 : #009bae,
    600 : #0093a7,
    700 : #00899d,
    800 : #007f94,
    900 : #006d84,
    A100 : #b1efff,
    A200 : #7ee5ff,
    A400 : #4bdaff,
    A700 : #31d5ff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

//Global

$my-primary: mat.define-palette($md-primary, 500);
$my-secondary: mat.define-palette($md-secondary, 500);
$my-warn: mat.define-palette(mat.$red-palette);
$my-theme: mat.define-light-theme((
 color: (
   primary: $my-primary,
   accent: $my-secondary,
   warn: $my-warn,
    
 )
));
@include mat.all-component-themes($my-theme);

//Colors
$color-config: mat.get-color-config($my-theme);
$primary-palette: map.get($color-config, 'primary');
$accent-palette:  map.get($color-config, 'accent');
$warn-palette:  map.get($color-config, 'warn');
$primary: mat.get-color-from-palette($primary-palette, 500);
$secondary: mat.get-color-from-palette($accent-palette, 500);
$light-secondary: mat.get-color-from-palette($accent-palette, 300);
$light-primary: mat.get-color-from-palette($primary-palette, 300);
$warn: mat.get-color-from-palette($warn-palette);

//PALETTE BASICS
$white: white;
$darkblue: #001b28;
$black: black;

:root {
    --primary: #{$primary};
    --secondary: #{$secondary};
    --light-secondary: #{$light-secondary};
    --light-primary: #{$light-primary};
    --warn: #{$warn};

    --white: #{$white};
    --darkblue: #{$darkblue};
    --black: #{$black};
}

