@use "src/styles/0-base/_responsive.scss" as *;

.reg_container {
  .items-affiliate-contain {
    display: flex;
    flex-direction: column;
    padding: 0 40px;
    padding-bottom: 40px;
    .affiliate-title {
      font-size: 28px;
      font-weight: 400;
      color: var(--light-mode-grey13);
      margin: 50px 0 40px 0;
      line-height: normal;
    }
  }
}

.graphic {
  width: 400px;
  height: 387px;
  border-radius: 8px;
  margin-bottom: 20px;
  background-color: var(--light-mode-grey3);
  padding: 10px 50px;
  .title-graphic {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 10px 15px 10px 15px;
    align-items: center;
    border-bottom: solid 1px var(--light-mode-grey8);
    margin-bottom: 10px;
    p {
      margin: 0;
    }
  }
}

@media (max-width: $tablet) {
  .reg_container {
    padding: 0;
    .items-affiliate-contain {
      padding: 20px 0px 20px;
      margin: 0 20px;
      .affiliate-title {
        font-size: 25px;
      }
    }
    .Page_container {
      margin: 0;
      padding: 0;
    }
  }
  .graphic {
    width: 300px;
  }
}

@media (max-width: $mobile) {
    .reg_container {
        .items-affiliate-contain {
          .affiliate-title {
            font-size: 24px;
            margin: 0;
            margin-bottom: 24px;
            line-height: normal;
          }
        }
      }
      .graphic{
        padding: 20px;
      }
}
