@use "src/styles/0-base/_responsive.scss" as *;
.container {
  margin: 0px 42.5px;
  padding: 0px 15px;
}
.page-container {
  background-color: white;
  max-width: 1366px;
  margin: 0 auto;
  .img-container {
    height: 350px;
    background-repeat: no-repeat;
    background-size: cover;
    max-width: 95%;
    margin: 0 auto;
    display: flex;
  }
}
.wide {
  background-color: #fffef7;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
}
.wide-dark {
  background-color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
}
@media (max-width:$mobile) {
  .page-container{
    max-width: 90%;
    margin-inline:auto;
  }
}




