@use "src/styles/0-base/_responsive.scss" as *;

.header-main-contain {
  margin-top: 20px;
  background-color: var(--light-mode-grey1);
  display: flex;
  justify-content: flex-start;
  margin-bottom: 5px;
  .imgs-contain {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-around;
    align-items: center;
  }

  .header-nav {
    display: flex;
    width: 100%;
    align-items: flex-end;
    .nav-list {
      margin: 0;
      margin-left: 40px;
      margin-right: 40px;
      width: 100%;
      gap: 5px;
      li {
        margin: 0;
        padding: 0;
      }
      padding: 0;
      display: flex;
      list-style: none;

      .nav-item {
        color: var(--light-mode-grey11);
        text-decoration: none;
        border-radius: 10px;
        padding: 10px 8px;
        /* margin: 26px 5px 0; */
        text-align: center;
        font-size: 14px;
        margin-top: 2px;
        cursor: pointer;
        width: 15.5%;       
        background-color: rgba(0, 0, 0, 0.096);
        transition: 0.3s;
        @media (max-width: 1024px){
            min-width: 100px;
            width: fit-content;
          }
        &:hover {
          background-color: var(--brand-blue);
          color: var(--brand-dark-blue);
        }
      }
      .activeRouteControlPanel,
      .activeRoute {
        color: var(--brand-dark-blue);
        background-color: var(--brand-blue);
      }
    }
  }
}

@media (max-width: $tablet) {
  .header-main-contain {
    display: none;
  }
}