
@mixin box {
    color: white;
    text-align: center;
    align-items: center;
    border-radius: 3px;
    padding-inline: 5px;
    font-size: 11px;
    
}

.Cancelada {
    @include box;
    background-color: var(--light-mode-grey9);
    font-size: 12px;
    border-radius: 4px;
    padding: 4px
    p{
        margin-bottom: 7px
    }
}

.Azules {
    @include box;
    background-color:var(--brand-blue);
    font-size: 12px;
    border-radius: 4px;
    padding: 4px
    p{
        margin-bottom: 7px
    }
}

.En-Estudio{
    @include box;
    background-color: var(--warning-500);
    font-size: 12px;
    border-radius: 4px;
    padding: 4px
    p{
        margin-bottom: 7px
    }
  }

.Verdes{
    @include box;
    background-color: var(--info-500);
    font-size: 12px;
    border-radius: 4px;
    padding: 4px
    p{
        margin-bottom: 7px
    }
}

.VerdeOscuro{
    @include box;
    background-color: var(--success-500);
    font-size: 12px;
    border-radius: 4px;
    padding: 4px
    p{
        margin-bottom: 7px
    }
}

/* .Analisis{
    @include box;
    background:rgb(213, 213, 8)
} */

.Denegada {
    @include box;
    background-color: var(--alert-500);
    font-size: 12px;
    border-radius: 4px;
    padding: 4px
    p{
        margin-bottom: 7px
    }
}

