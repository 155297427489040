
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap');

$font: 'Geomanist', sans-serif;
$secondFont: 'Natom', sans-serif;

@font-face {
  font-family: 'Geomanist';
  src: url('../../assets/fonts/geomanist-regular-webfont.woff2') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Geomanist';
  src: url('../../assets/fonts/geomanist-regular-italic-webfont.woff2') format('truetype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Natom';
  src: url('../../assets/fonts/natompro-titleblack-webfont.woff2') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@mixin base-typo($size, $weight, $spacing) {
    font-weight: $weight;
    font-size: $size;
    letter-spacing: $spacing;
  }

:root {
  --font: #{$font};
  --secondFont: #{$secondFont};
}