.tableMaterial {
  width: 100%;
  margin: 0 auto;
  text-align: center;
  font-size: 12px;
  .mat-mdc-header-cell {
    text-align: center;
    text-align: left;
    padding: 0 5px;
    font-size: 11px;
    color: black;
    border-bottom: 2px solid var(--primary);
    border-top: 2px solid var(--primary);
    
  }
  .mat-mdc-footer-row,
  .mat-mdc-header-row,
  .mat-mdc-row {
    height: 50px;
  }
  td{
    font-size: 11px;
    text-align: left;
    padding: 0 5px;
    border-color:  rgb(204, 203, 203);
    border-width: 1.4px;
    p{
      text-align: center ;
    }
  }
  .mat-mdc-row:hover .mat-mdc-cell {
    background-color: whitesmoke;
  }
  .mat-mdc-cell:last-of-type{
    padding-right: 0;
  }

}
@media(max-width: 1200px) {
    
  .tableMaterial {
    max-width: 97%;
    display: block;
    overflow-x: auto;
    white-space: nowrap;
  }
}