
// Import material theming functions
@use 'SASS:map';
@use '@angular/material' as mat;

//theme 
$md-primary: (
    50 : #e0e6e8,
    100 : #b3c0c5,
    200 : #80969f,
    300 : #4d6b78,
    400 : #264c5b,
    500 : #002c3e,
    600 : #002738,
    700 : #002130,
    800 : #001b28,
    900 : #00101b,
    A100 : #59a7ff,
    A200 : #268bff,
    A400 : #0071f2,
    A700 : #0065d9,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #ffffff,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);

/* For use in src/lib/core/theming/_palette.scss */
$md-secondary: (
    50 : #e0f3f5,
    100 : #b3e1e7,
    200 : #80cdd7,
    300 : #4db9c6,
    400 : #26aaba,
    500 : #009bae,
    600 : #0093a7,
    700 : #00899d,
    800 : #007f94,
    900 : #006d84,
    A100 : #b1efff,
    A200 : #7ee5ff,
    A400 : #4bdaff,
    A700 : #31d5ff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

//Global

$my-primary: mat.m2-define-palette($md-primary, 500);
$my-secondary: mat.m2-define-palette($md-secondary, 500);
$my-warn: mat.m2-define-palette(mat.$m2-red-palette);
$my-theme: mat.m2-define-light-theme((
 color: (
   primary: $my-primary,
   accent: $my-secondary,
   warn: $my-warn,
    
 )
));
@include mat.all-component-themes($my-theme);

//Colors
$color-config: mat.m2-get-color-config($my-theme);
$primary-palette: map.get($color-config, 'primary');
$accent-palette:  map.get($color-config, 'accent');
$warn-palette:  map.get($color-config, 'warn');
$primary: mat.m2-get-color-from-palette($primary-palette, 500);
$secondary: mat.m2-get-color-from-palette($accent-palette, 500);
$light-secondary: mat.m2-get-color-from-palette($accent-palette, 300);
$light-primary: mat.m2-get-color-from-palette($primary-palette, 300);
$warn: mat.m2-get-color-from-palette($warn-palette);

//PALETTE BASICS
$white: white;
$darkblue: #001b28;
$black: black;

//NEW COLORS

//LIGHT MODE
$light-mode-grey1: #FCFCFD;
$light-mode-grey2: #F9F9FB;
$light-mode-grey3: #eff0f3;
$light-mode-grey4: #E7E8EC;
$light-mode-grey5: #E0E1E6;
$light-mode-grey6: #D8D9E0;
$light-mode-grey7: #CDCED7;
$light-mode-grey8: #B9BBC6;
$light-mode-grey9: #8B8D98; 
$light-mode-grey10: #80828D;
$light-mode-grey11: #62636C;
$light-mode-grey12: #19191B;
$light-mode-grey13: #111113;

//DARK MODE

$dark-mode-grey1: #111113;
$dark-mode-grey2: #19191B;
$dark-mode-grey3: #222325;
$dark-mode-grey4: #292A2E;
$dark-mode-grey5: #303136;
$dark-mode-grey6: #393A40;
$dark-mode-grey7: #46484F;
$dark-mode-grey8: #5F606A;
$dark-mode-grey9: #6C6E79;
$dark-mode-grey10: #797B86;
$dark-mode-grey11: #B2B3BD;
$dark-mode-grey12: #EEEEF0;
$dark-mode-grey13: #ffffff;


//ALERT

$alert-50: #FFC9c6;
$alert-500: #FF3B30;
$alert-800: #6D0F0A;

//SUCCESS

$success-50: #CCF9D4;
$success-500: #34C759;
$success-800: #0c661d;

//WARNING

$warning-50: #FFEACC;
$warning-500: #FF9500;

//INFORMATION

$info-50: #CCE4FF;
$info-500: #007AFF;

//BRAND

$brand-blue: #1BD6FF;
$brand-dark-blue: #082231;
 



:root {
    --light-mode-grey13: #{$primary};
    --secondary: #{$secondary};
    --light-secondary: #{$light-secondary};
    --light-primary: #{$light-primary};
    --warn: #{$warn};
    --white: #{$white};
    --darkblue: #{$darkblue};
    --black: #{$black};
    --light-mode-grey1: #{$light-mode-grey1};
    --light-mode-grey2: #{$light-mode-grey2};
    --light-mode-grey3: #{$light-mode-grey3};
    --light-mode-grey4: #{$light-mode-grey4};
    --light-mode-grey5: #{$light-mode-grey5};
    --light-mode-grey6: #{$light-mode-grey6};
    --light-mode-grey7: #{$light-mode-grey7};
    --light-mode-grey8: #{$light-mode-grey8};
    --light-mode-grey9: #{$light-mode-grey9};
    --light-mode-grey10: #{$light-mode-grey10};
    --light-mode-grey11: #{$light-mode-grey11};
    --light-mode-grey12: #{$light-mode-grey12};
    --light-mode-grey13: #{$light-mode-grey13};
    --dark-mode-grey1: #{$dark-mode-grey1};
    --dark-mode-grey2: #{$dark-mode-grey2};
    --dark-mode-grey3: #{$dark-mode-grey3};
    --dark-mode-grey4: #{$dark-mode-grey4};
    --dark-mode-grey5: #{$dark-mode-grey5};
    --dark-mode-grey6: #{$dark-mode-grey6};
    --dark-mode-grey7: #{$dark-mode-grey7};
    --dark-mode-grey8: #{$dark-mode-grey8};
    --dark-mode-grey9: #{$dark-mode-grey9};
    --dark-mode-grey10: #{$dark-mode-grey10};
    --dark-mode-grey11: #{$dark-mode-grey11};
    --dark-mode-grey12: #{$dark-mode-grey12};
    --dark-mode-grey13: #{$dark-mode-grey13};
    --alert-50: #{$alert-50};
    --alert-500: #{$alert-500};
    --alert-800: #{$alert-800};
    --success-50: #{$success-50};
    --success-500: #{$success-500};
    --success-800: #{$success-800};
    --warning-50: #{$warning-50};
    --warning-500: #{$warning-500};
    --info-50: #{$info-50};
    --info-500: #{$info-500};
    --brand-blue: #{$brand-blue};
    --brand-dark-blue: #{$brand-dark-blue};

    //Mb medidas para mobile  Tb para tablet DK para desktop
    --SizeheadingH1Dk: 58px;
    --SizeheadingH1: 32px;
    --SizeheadingH1Mb: 40px;
    --SizeheadingH2Mb: 28px;
    --SizeheadingH3Mb: 21px;
    --SizeheadingH3Tb: 24px;
    --SizeheadingH3Dk: 28px;
    --SizeheadingH4-MediumMb: 18px;
    --SizeheadingH4-MediumDk: 24px;
    --SizegeneralBodyL: 16px;
    --SizegeneralTags: 14px;

    --radiusradius-xs: 8px;
    --radiusradius-sm: 16px;
    --radiusradius-xl: 100px;

    --SizegeneralButton: 16px;

    --spacing1: 16px;
    --spacing15:24px;
    --spacing2: 32px;
    --spacing25: 40px;
    --spacing3: 48px;
    --spacing5: 80px;
    --spacing75: 120px;
    --spacing95: 152px;
}



