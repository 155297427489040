.tableMaterial {
  width: 100%;
  margin: 0 auto;
  text-align: center;
  font-size: small;
  background-color: transparent;
  scrollbar-color:  var(--brand-dark-blue) whitesmoke;
  scrollbar-width: thin;
  table-layout: fixed !important;
  .mat-mdc-header-cell {
    text-align: center;
    text-align: left;
    padding: 0 5px;
    font-size: 12px;
    color: var(--light-mode-grey13);
    border-bottom: 2px solid rgb(204, 203, 203);
    white-space: nowrap;
  }
  
  .mat-mdc-footer-row,
  .mat-mdc-header-row,
  .mat-mdc-row {
    height: 50px;
  }
  td{
    font-size: small;
    text-align: left;
    padding: 0 5px;
    border-color:  rgb(204, 203, 203);
    border-width: 1.4px;
    background-color: var(--light-mode-grey1) !important;
    white-space: nowrap;
    p{
      text-align: center ;
    }
  }
  th{
    background-color: var(--light-mode-grey1) !important;
  }
  .mat-mdc-row:hover .mat-mdc-cell {
    background-color: transparent;
  }
  .mat-mdc-cell:last-of-type{
    padding-right: 0;
  }

}
@media(max-width: 1200px) {
    
  .tableMaterial {
    max-width: 97%;
    display: block;
    overflow-x: auto;
    white-space: nowrap;
  }
}